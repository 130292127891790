<template>
  <b-container>
    <b-row align-h="center">
      <b-col cols="6">
        <b-card>
          <b-row>
            <b-col>
              <b-form-group label="NIP" label-for="nip">
                <b-form-input id="nip" v-model="form.nip" required />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Name" label-for="name">
                <b-form-input id="name" v-model="form.name" required />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Contact" label-for="contact">
                <b-form-input
                  id="contact"
                  v-model="form.contact"
                  type="number"
                  onKeyPress="if(this.value.length==13) return false;"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Email" label-for="email">
                <b-form-input id="email" v-model="form.email" required />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Organization" label-for="organization">
                <v-select
                  id="organization"
                  v-model="selectedOrganization"
                  :options="optionsOrganization"
                  label="text"
                  required
                />
                <!-- Selected: <strong>{{ selectedOrganization }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Role" label-for="role">
                <v-select
                  id="role"
                  v-model="selectedRole"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="optionsRole"
                  label="text"
                  required
                />
                <!-- Selected: <strong>{{ selectedRole }}</strong> -->
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="6">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="success"
          class="mr-1"
          @click="addEmployee()"
          block
        >
          Submit
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BFormTextarea,
  BTab,
  BTabs,
  BAlert,
  BLink,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormRadio,
  BFormDatepicker,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import VueThousandSeparator from "vue-thousand-separator";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";

export default {
  components: {
    Cleave,
    VueThousandSeparator,
    ToastificationContent,
    flatPickr,
    vSelect,
    BFormTextarea,
    BTab,
    BTabs,
    BAlert,
    BLink,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormRadio,
    BFormDatepicker,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    draggable,
    Prism,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        nip: "",
        name: "",
        email: "",
        contact: null,
      },
      user_id: null,
      state: null,
      selectedOrganization: null,
      optionsOrganization: [],
      selectedRole: null,
      optionsRole: [],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      errMessage: "",
    };
  },
  watch: {
    selectedOrganization: function (data) {
      this.trigger(data.value);
    },
  },
  created() {
    this.getOrganization();
    this.getRoles();
  },
  methods: {
    getOrganization() {
      this.$http.get("organizations").then((res) => {
        this.optionsOrganization.push({
          value: null,
          text: "Please select 1 organization",
        });

        var datas = res.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.optionsOrganization.push($data);
        }
        // console.log(this.optionsOrganization)
      });
    },
    getRoles() {
      this.$http.get("user/roles").then((res) => {
        this.optionsRole.push({ value: null, text: "Please select role" });

        var datas = res.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.optionsRole.push($data);
        }
        // console.log(this.optionsRole)
      });
    },
    formatRupiah(money) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(money);
    },
    trigger(id) {
      this.$http.get("divisions/all?organization_id=" + id).then((res) => {
        this.optionsDivision = [];
        this.selectedDivision = null;
        this.optionsDivision.push({
          value: null,
          text: "Please select 1 division",
        });

        var datas = res.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.optionsDivision.push($data);
        }
        // console.log(this.optionsDivision)
      });
    },
    validasi1() {
      if (this.form.nip === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The NIP field is required",
            variant: "warning",
          },
        });
      } else if (this.form.name === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Name field is required",
            variant: "warning",
          },
        });
      } else if (this.form.email === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Email field is required",
            variant: "warning",
          },
        });
      } else if (this.form.contact === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Phone field is required",
            variant: "warning",
          },
        });
      } else if (this.selectedOrganization === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Organization field is required",
            variant: "warning",
          },
        });
      } else if (this.selectedRole === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Role field is required",
            variant: "warning",
          },
        });
      }
    },
    postDetails() {
      this.$http
        .post("employees/add", {
          nip: this.form.nip,
          name: this.form.name,
          email: this.form.email,
          phone: this.form.contact,
          organization: this.selectedOrganization.value,
          role_id: this.selectedRole.value
        })
        .then((response) => {
          location.href = "/users"
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    addEmployee() {
      if (this.state === null) {
        this.validasi1();
        this.postDetails();
      } 
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>